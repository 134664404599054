import {
    DisciplineEnum,
    FootballStateEnum,
    FutsalStateEnum,
    LicenseApiInterface,
    OrganisationUnitApiInterface,
    ClubRegistrationApiInterface,
    AddressApiInterface,
    TagApiInterface,
} from '../ApiInterface';

export enum GenderEnum {
    male = 'male',
    female = 'female',
}

export type PersonRoleStateType =
    | 'agent-football'
    | 'agent-futsal'
    | 'player-football'
    | 'player-futsal'
    | 'coach-football'
    | 'coach-futsal'
    | 'delegate-football'
    | 'delegate-futsal'
    | 'referee-football'
    | 'referee-futsal'
    | 'technical_delegate-football'
    | 'technical_delegate-futsal'
    | 'masseur-football'
    | 'masseur-futsal'
    | 'paramedic-football'
    | 'paramedic-futsal'
    | 'physician-football'
    | 'physician-futsal';

export enum RoleApiEnum {
    member = 'member',
    'agent-football' = 'agent-football',
    'agent-futsal' = 'agent-futsal',
    'coach-football' = 'coach-football',
    'coach-futsal' = 'coach-futsal',
    'delegate-football' = 'delegate-football',
    'delegate-futsal' = 'delegate-futsal',
    'player-football' = 'player-football',
    'player-futsal' = 'player-futsal',
    'referee-football' = 'referee-football',
    'referee-futsal' = 'referee-futsal',
    'technical_delegate-football' = 'technical_delegate-football',
    'technical_delegate-futsal' = 'technical_delegate-futsal',
    'masseur-football' = 'masseur-football',
    'masseur-futsal' = 'masseur-futsal',
    'paramedic-football' = 'paramedic-football',
    'paramedic-futsal' = 'paramedic-futsal',
    'physician-football' = 'physician-football',
    'physician-futsal' = 'physician-futsal',
}

export interface PersonDataApiInterface {
    id: number;
    link_id: number | null;
    first_name: string | null;
    middle_name: string;
    last_name: string;
    name: string;
    title: string;
    email: string;
    phone: string;
    photo: string;
    gender: GenderEnum;
    birth_date: string;
    birth_year: number | null;
    birth_country_code: string;
    active_in_club_ids: number[];
    has_role_in_any_club_ids: number[];
    national_identifier: string;
    birth_city: string;
    citizen_of: string;
    second_citizen_of: string;
    language: string;
    address: AddressApiInterface;
    updated_at: string;
    created_at: string;
}

export interface ContactApiInterface {
    id?: number;
    type: ContactApiEnum;
    value: string;
    is_main?: boolean;
    verified_at?: string;
    created_at?: string;
}

export enum ContactApiEnum {
    email = 'email',
    phone = 'phone',
    czech_data_box = 'czechDataBox',
}

export type DisciplineStateType = 'football_state' | 'futsal_state';

export interface MemberApiInterface {
    internal_id: string;
    football_state: FootballStateEnum;
    futsal_state: FutsalStateEnum;
    roles: RoleApiEnum[];
    indications: TagApiInterface[];
}

export enum MemberIndicationApiEnum {
    'ban_for_playing' = 'ban_for_playing',
}

export interface PlayerApiInterface {
    football: PlayerDisciplineApiInterface;
    futsal: PlayerDisciplineApiInterface;
    competition: PlayerCompetitionApiInterface;
}

export enum PlayerStateApiEnum {
    'domestic_registration' = 'domestic_registration',
    'domestic_hosting' = 'domestic_hosting',
    'international_registration' = 'international_registration',
    'international_hosting' = 'international_hosting',
    'no_registration' = 'no_registration',
}

export enum PlayerLevelApiEnum {
    'amateur' = 'amateur',
    'pro' = 'pro',
}

export interface PlayerDetailApiInterface {
    active: boolean;
    level: PlayerLevelApiEnum;
    state: PlayerStateApiEnum;
    registered_in: ClubRegistrationApiInterface;
    hosting_in: ClubRegistrationApiInterface;
    sub_hosting_in: ClubRegistrationApiInterface;
    active_in: ClubRegistrationApiInterface;
    archived_at: string | null;
}

export interface PlayerDisciplineApiInterface {
    registered: PlayerDisciplineTeamApiInterface;
    hosting: PlayerDisciplineTeamApiInterface;
}

export interface PlayerDisciplineTeamApiInterface {
    id: number;
    name: string;
}

export interface PlayerCompetitionApiInterface {
    teams: PlayerCompetitionTeamApiInterface[];
}

export interface PlayerCompetitionTeamApiInterface {
    id: number;
    club_id: number;
    name: string;
    competition_year: number;
}

export interface NominationApiInterface {
    id: number;
    competition_year: number;
    organisation_unit: OrganisationUnitApiInterface;
}

export interface RefereeDelegateApiInterface {
    licenses: Record<DisciplineEnum, LicenseApiInterface>;
    nominations: Record<DisciplineEnum, NominationApiInterface>;
}

export interface CoachApiInterface {
    licenses: Record<DisciplineEnum, LicenseApiInterface>;
}

export interface MemberListApiInterface {
    member: MemberApiInterface;
    person: PersonDataApiInterface;
}

export interface MemberPlayerListApiInterface {
    member: MemberApiInterface;
    player: PlayerApiInterface;
    person: PersonDataApiInterface;
}

/** An interface for displaying a list of members on a member search page. */
export interface MemberListPageApiInterface {
    /** person id, if null, don't display link to member detail. */
    link_id: number | null;
    internal_id: string | null;
    external_id: string | null;
    football_state: FootballStateEnum;
    futsal_state: FutsalStateEnum;
    first_name: string;
    last_name: string;
    citizen_of: string;
    birth_year: number | null;
    /** Optional for foreigners */
    national_identifier: string | null;
    gender: GenderEnum;
    /** Roles  */
    roles: RoleApiEnum[];
}

export interface RefereeListApiInterface {
    referee: RefereeDelegateApiInterface;
    person: PersonDataApiInterface;
    member: MemberApiInterface;
}

export interface CoachListApiInterface {
    coach: CoachApiInterface;
    person: PersonDataApiInterface;
    member: MemberApiInterface;
}

export interface DelegateListApiInterface {
    delegate: RefereeDelegateApiInterface;
    person: PersonDataApiInterface;
    member: MemberApiInterface;
}

export interface PersonSimpleApiInterface {
    name: string;
    internal_id: string;
}

export interface PersonBasicDataWithHasLicenceApiInterface {
    person_id: number;
    internal_id: string | null;
    name: string;
    has_active_license: boolean;
}

export interface PersonBasicDataApiInterface {
    person_id: number;
    internal_id: string | null;
    name: string;
}

export interface PersonLicenseApiInterface {
    id: number;
    is_active: boolean;
    discipline: DisciplineEnum;
    role: RoleApiEnum;
    type: PersonLicenseTypeApiEnum;
    person: PersonBasicDataApiInterface;
    granted_by_organisation_unit: OrganisationUnitApiInterface;
    granted_by_person: PersonBasicDataApiInterface;
    valid_from: string;
    valid_to: string;
    deactivated_at: string;
    archived_at: string;
    created_at: string;
    updated_at: string;
}

export enum PersonLicenseTypeApiEnum {
    A = 'A',
    P = 'P',
    C = 'C',
}

export enum RefereeLicenseTypeApiEnum {
    A = 'A',
    P = 'P',
    C = 'C',
}

export enum DelegateLicenseTypeApiEnum {
    A = 'A',
    P = 'P',
}

export enum CoachLicenseTypeApiEnum {
    association_c = 'association_c',
    association_condition_coach = 'association_condition_coach',
    association_pro = 'association_pro',
    association_ucft_a = 'association_ucft_a',
    uefa_a = 'uefa_a',
    uefa_a_gk = 'uefa_a_gk',
    uefa_a_youth_coach = 'uefa_a_youth_coach',
    uefa_b = 'uefa_b',
    uefa_b_gk = 'uefa_b_gk',
    uefa_c = 'uefa_c',
    uefa_pro = 'uefa_pro',
}

export type LicenseTypeApiEnum =
    | PersonLicenseTypeApiEnum
    | CoachLicenseTypeApiEnum;

export enum PersonRoleNoDisciplineEnum {
    member = 'member',
    agent = 'agent',
    coach = 'coach',
    delegate = 'delegate',
    player = 'player',
    referee = 'referee',
    technical_delegate = 'technical_delegate',
    masseur = 'masseur',
    paramedic = 'paramedic',
    physician = 'physician',
}

export interface PersonLicenseCreatePayloadApiInterface {
    discipline: DisciplineEnum;
    role:
        | PersonRoleNoDisciplineEnum.delegate
        | PersonRoleNoDisciplineEnum.referee;
    type: PersonLicenseTypeApiEnum;
    granted_by_organisation_unit_id: number;
    valid_from: string;
    valid_to: string;
}

export interface PersonAbsenceApiInterface {
    id: number;
    valid_from: string;
    valid_to: string;
    note?: string;
}

export interface PersonAsbenceEditInterface {
    valid_from: string;
    valid_to: string;
    note?: string;
}

export interface PersonUpdateNameApiInterface {
    first_name: string;
    middle_name: string | null;
    last_name: string;
    title: string | null;
}

export interface PersonUpdateMoreInfoApiInterface {
    birth_city: string;
    birth_country_code: string;
    language: string;
    second_citizen_of: string | null;
}

export interface PersonUpdateContactsApiInterface {
    address: {
        city: string;
        country_code: string;
        postal_code: string;
        street: string;
        street_number: string;
    };
    contacts: {
        phones: string[];
        emails: string[];
    };
    main_email: string;
}

export interface PersonUpdateNameApiInterface {
    first_name: string;
    middle_name: string | null;
    last_name: string;
    title: string | null;
}

export interface PersonUpdateMoreInfoApiInterface {
    birth_city: string;
    birth_country_code: string;
    language: string;
    second_citizen_of: string | null;
}

export interface PersonUpdateContactsApiInterface {
    address: {
        city: string;
        country_code: string;
        postal_code: string;
        street: string;
        street_number: string;
    };
    contacts: {
        phones: string[];
        emails: string[];
    };
    main_email: string;
}
